.profile-login{
    transition: 0s;
    cursor: pointer;
    position: absolute;
    transform: translateX(500px);
    top:30px;
    height: 150px;
    width: 230px;
    background-color: white;
    z-index: 3;
    display: block;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid var(--orange-home);
    .profile-login-logout{
        font-size: 18px;
        font-weight: 500;
        text-align: center;
        padding-left: 20px;
        cursor: pointer;
        height: 50px;
        p {
            padding-top: 15px;
        }
 
    }
    .profile-login-item{
        color:black;
        font-size: 18px;
        font-weight: 400;
        display: flex;
        height: 50px;
        align-items: center;
        justify-content: space-around;
        p{
            padding-top: 15px;
        }
    }
}