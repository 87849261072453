.header-dropdown {
  // width: calc(100% / 5);
  float: right;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .header-dropdown-favorits {
    img {
      width: 26px;
      height: 26px;
    }
  }
  .header-dropdown-bag {
    margin-left: 22px;
  }
  .header-dropdown-profile {
    margin-right: 22px;
    margin-left: 22px;
    position: relative;
  }
  .header-dropdown-profile:hover {
    .profile-login {
      // display: block;
      transition: 0.5s;
      transform: translateX(-150px);
    }
  }
}

.menuBar {
  // padding: 0 20px;
  // border-bottom: solid 1px #e8e8e8;
  // overflow: auto;
  // box-shadow: 0 0 30px #f3f1f1;
}

@media screen and (max-width: 1200px) {
  .header-dropdown {
    .header-dropdown-favorits {
      margin-left: 0px;
    }
    .header-dropdown-bag {
      margin-left: 0px;
    }
    .header-dropdown-profile {
      margin-right: 0px;
      margin-left: 0px;
    }
  }
}
