.gallery {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  // grid-template-rows: repeat(8, 5vw);
  grid-gap: 15px;
}
.gallery__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 14px;
}
.gallery__item--0 {
  grid-column-start: 1;
  grid-column-end: 7;
  grid-row-start: 1;
  grid-row-end: 5;
}
.gallery__item--1 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 1;
  grid-row-end: 3;
}
.gallery__item--2 {
  grid-column-start: 7;
  grid-column-end: 9;
  grid-row-start: 3;
  grid-row-end: 5;
}