@use "../../../../assets/common/utilities/mixins" as mixin;

.header-body {
  // @include mixin.flex(center, space-between);
  display: flex;
  height: 105px;
  grid-template-columns: 1.4fr 0.7fr;
  // grid-template-columns: 0.7fr 0.7fr;
  // padding: 0 33px;
  &-home {
    @include mixin.flex(center, space-between);
    height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .header-body {

    &-home {
      @include mixin.flex(center);
    }
  }
}
