.choose-ticket-header{
    border-bottom: 3px solid #ccc;
    display: flex;
    padding-bottom: 4px;
    .participate{
      flex: 7 1;
      text-align: start
    }
    .ticket-price{
      flex: 1 1;
    }
    .quantity{    
      display: flex;
      flex: 2 1;
      justify-content: center;
    }
}
.choose-ticket-body{    
  display: flex;
  margin-top: 18px;
  .content-participate{
    flex: 7 1;
    text-align: start;
    align-self: center;
  }
  .content-price{
    flex: 1 1;
    align-self: center;
  }
  .content-quantity{
    display: flex;
    flex: 2 1;
    justify-content: center;
  }
}

