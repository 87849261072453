#parent {
  margin        : 0;
  padding       : 0;
  display       : grid;
  place-content : center;
  min-height    : 100vh;
  background-image: url("../../../../assets/images/home/backgroundimage.png");
}
#form_login{
  border-radius: 10px;
  width: 720px;
  height: 393px;
  background-color: #fff;
}
.box-input-changepass{
  margin-top: 92px;
  font-size: 32px;
}
.form-input-forgot{
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  input{
    width: 500px;
    display: block;
    outline: none;
    border: none;
    height: 2em;
    font-size: 16px;
    margin-bottom: 1px;
    border-bottom: 1px solid #4EAC2B;
  }
  .error{
    color: red;
  }
}