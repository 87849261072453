.loginnormal{
    height: 50px;
    width: 100%;
    line-height: 35px;
    background-color: var(--orange-home);
    color:white;
    font-size: 16px;
    margin-right: 35px;
    cursor: pointer;
    font-weight: 500;
    border-radius: 6px;
    text-align: center;
    padding-top: 7px;
}