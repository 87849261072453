.parent{
    display: none;
    transition: 0s;
    cursor: pointer;
    position: absolute;
    transform: translateX(500px);
    top:30px;
    height: fit-content;
    width: 300px;   
    background-color: white;
    z-index: 3;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 3px solid var(--orange-home);
    color: black;
    font-weight: 400;
    .cateName{
        font-size: 18px;
        font-weight: 500;
        color: rgba(34, 34, 34, 0.5);;
    }:hover{
        color: #000;
    }
}
