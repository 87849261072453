.content-main{
  padding: 0 5%;
  width:100%;
  .cooperate-warp{
    padding: 30px 70px;
    width: 100%;
    .cooperate-title{
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      h1{
        font-size: 26px;
        font-weight: 500;
        line-height: 3rem;
        margin-bottom: 12px;
      }
      span{
        font-size: 18px;
        font-weight: 400;
      }
    }
    .investments-list{    
      display: flex;
      flex-wrap: wrap;
      gap: 18px;
      justify-content: center;
      .investments-item{
        display: flex;
        flex-direction: column;
        row-gap: 12px;
        .investments-image{
          height: 175px;
          position: relative;
          width: 280px;
          .img{    
            border: 0.739992px solid #4285f4;
            border-radius: 10px;
            box-shadow: inset 0 1.47998px 2.95997px rgb(0 0 0 / 25%);
            height: 175px;
            width: 280px;
          }
          .investments-funds-name{
            bottom: 10px;
            color: #fff;
            display: flex;
            justify-content: center;
            left: 0;
            position: absolute;
            width: 100%;
          }
        }
        .investments-detail{
          border: 0.95719px solid #4285f4;
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          min-height: 362px;
          padding: 20px;
          width: 280px;
          .investments-detail-content{
            flex: 1 1;
            .investments-detail-title{
              font-size: 16px;
              font-weight: 500;
              line-height: 2.2rem;
              display: flex;
            }
            ul{
              text-align: left;
              padding: 0 20px;
              li{
                list-style-type: circle;
              }
            }
          }
          .investments-detail-bottom{    
            display: flex;
            flex-direction: column;
            .investments-detail-price{
              display: flex;
              font-size: 20px;
              font-weight: 500;
              justify-content: center;
            }
            .investments-detail-action{
              -webkit-column-gap: 8px;
              column-gap: 8px;
              display: flex;
              justify-content: center;
              margin-top: 12px;
              .register-now{
                padding: 5px 25px;
                border-radius: 5px;
                background-color: red;
                color: #fff;
                transition: .3s;
              }:hover{
                cursor: pointer;
                opacity: .7;
              }
            }
          }
        }
      }
    }
  }
}
