.auth-register {
  margin-left: 25px;
  .auth-register-btn {
    border-radius: 25px;
    height: 59px;
    width: 232px;
    background-color: var(--orange-home);
    border: 1px solid var(--orange-home);
    font-size: 22px;
    cursor: pointer;
    padding-top: 12px;
    color: white;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}
.handle-register {
  margin-top: 40px;
  height: 39px;
  border-radius: 5px;
  width: 100%;
  cursor: pointer;
  position: relative;
  color: white;
  text-align: center;
  p {
    padding-top: 7px;
    font-size: 16px;
    font-weight: 500;
  }
  background-color: var(--orange-home);
}

.handle-cancel-register {
  margin-top: 40px;
}
// .MuiBox-root {
//   .box-main-login {
//     height: 100%;
//     .box-main-form {
//       // margin-top: 50px;
//       .box-main-phone {
//         display: grid;
//         width: 100%;
//         grid-template-columns: 1fr 1fr;
//         gap: 40px;
//         input {
//           width: 100%;
//         }
//       }
//     }
//   }
// }

.verify-form {
  // padding: 30px;
  position: relative;
  img {
    width: 21px;
    margin-bottom: 20px;
  }
  &-btn {
    margin-top: 20px;
  }
  &-noti {
    // float: right;
    .MuiSnackbar-root {
      padding: 200px;
    }
  }
}

.FormSignUp {
  &__Input__Verify {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0px 50px;

    input {
      width: 45px;
      height: 45px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__I {
      border-bottom: 1px solid #369313;
      color: #222222;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
    }
  }
}
